import styled from "styled-components";
import { DEVICE } from "../../const/mediaQuery";
import background from "../../assets/boxbackground.webp";

export const MainWrapper = styled.div`
  flex: 1;
  min-height: 400px;
  display: flex;
  margin: auto;
  max-width: 900px;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${DEVICE.laptop} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const ContainerWrapper = styled.div`
  width: 345px;
  height: 225px;
  background-image: url(${background});
  background-size: cover;
  border-radius: 10px;
  color: white;
  margin-bottom: 20px;

  @media ${DEVICE.tablet} {
    width: 400px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 24px);
  flex-direction: column;
  justify-content: space-around;
  font-size: 32px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  img {
    object-fit: contain;
  }
`;
