import styled from "styled-components";

export const LocationWrapper = styled.div`
  position: relative;
  height: 800px;
  max-width: 800px;
  color: white;
`;
export const TextWrapper = styled.div`
  padding: 12px;
`;
