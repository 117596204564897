import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import * as S from "../Main.styles";
import type { ContainerType } from "./container.types";

export const Container = ({ icon, link, title }: ContainerType) => {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <S.ContainerWrapper>
        <S.ContentWrapper>
          <S.IconWrapper>
            <img src={icon} alt="Container img" height={64} width={64} />
          </S.IconWrapper>
          <FormattedMessage id={title} defaultMessage={title} />
        </S.ContentWrapper>
      </S.ContainerWrapper>
    </Link>
  );
};
