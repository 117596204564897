import { Container } from "./Container/Container";
import * as S from "./Main.styles";
import { TILES } from "./main.constants";

export const Main = () => {
  return (
    <S.MainWrapper>
      {TILES.map((item, index) => (
        <div key={`${index}`}>
          <Container
            icon={item.icon}
            link={item.link}
            title={item.title}
          />
        </div>
      ))}
    </S.MainWrapper>
  );
};
