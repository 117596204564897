import { APP_LINKS } from "../Main/main.constants";
import PolandFlag from "../../assets/poland.svg";
import UkraineFlag from "../../assets/ukraine.svg";
import USAFlag from "../../assets/usa.svg";

export const NAVBAR_LIST = [
  {
    text: "HEADER_MAIN",
    link: "/",
  },
  {
    text: "HEADER_ABOUT_US",
    link: APP_LINKS.ABOUT,
  },
  {
    text: "HEADER_SERVICES",
    link: APP_LINKS.TOOLS,
  },
  {
    text: "HEADER_LOCATION",
    link: APP_LINKS.LOCATION,
  },
  {
    text: "HEADER_CONTACT",
    link: APP_LINKS.CONTACT,
  },
];

export const AVAILABLE_LANGUAGE ={
  PL: 'pl',
  EN: 'en',
  UA: 'ua',
}

export const AVAILABLE_LANGUAGES_LIST = {
  [AVAILABLE_LANGUAGE.PL] : {
    text: AVAILABLE_LANGUAGE.PL,
    icon: PolandFlag,
  },
  [AVAILABLE_LANGUAGE.EN]: {
    text: AVAILABLE_LANGUAGE.EN,
    icon: USAFlag,
  },
  [AVAILABLE_LANGUAGE.UA]: {
    text: AVAILABLE_LANGUAGE.UA,
    icon: UkraineFlag,
  }
}