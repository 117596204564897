import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import type { ChangeEvent } from "react";
import * as S from "./Contact.styles";

export const Contact = () => {
  const { formatMessage } = useIntl();
  const [fileName, setFileName] = useState<string>("");

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value.toString().replace(/C:\\fakepath\\/, ""));
  };

  return (
    <S.ContactWrapper>
      <h1><FormattedMessage id="CONTACT_FIRST_PARAGRAPH" defaultMessage='Napisz do nas!'/></h1>
      <form
        target="_blank"
        action="https://formsubmit.co/fa14f04b50db5ab7f8bdb1c38b36caf7"
        method="POST"
        encType="multipart/form-data"
      >
        <S.FormWrapper>
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder={formatMessage({ id: "CONTACT_NAME", defaultMessage: "Imię" })}
            required
          />
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder={formatMessage({ id: "CONTACT_EMAIL", defaultMessage: "Adres e-mail" })}
            required
          />
          <input
            type="text"
            name="car_brand"
            className="form-control"
            placeholder={formatMessage({ id: "CONTACT_CAR_TYPE", defaultMessage: "Marka samochodu (Volvo, Audii, ...)" })}
            required
          />
          <input
            type="text"
            name="car_model"
            className="form-control"
            placeholder={formatMessage({ id: "CONTACT_CAR_MODEL", defaultMessage: "Model samochodu (XC60, Q5, ...)" })}
            required
          />
          <input
            type="number"
            min={1950}
            max={2100}
            name="production_year"
            className="form-control"
            placeholder={formatMessage({ id: "CONTACT_YEAR", defaultMessage: "Rok produkcji" })}
            required
          />
          <label htmlFor="img" className="btn btn-info">
            {!fileName ? formatMessage({ id: "CONTACT_ADD_PHOTO", defaultMessage: "Dodaj zdjęcie" }) : `${formatMessage({ id: "CONTACT_ADDED_PHOTO", defaultMessage: "Załączone zdjęcie: " }) }${fileName}`}
          </label>
          <input
            id="img"
            type="file"
            name="attachment"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
            onChange={handleUploadFile}
          />
          <textarea
            placeholder={formatMessage({ id: "CONTACT_ADDITIONAL_INFO", defaultMessage: "Wyposażenie samochodu ( kamera, czujnik deszczu, czujnik zmierzchu, ... ) oraz dodatkowe informacje dla naszego pracownika :)" })}
            className="form-control"
            name="message"
            rows={10}
          />
          <button type="submit"><FormattedMessage id="CONTACT_SEND_MESSAGE" defaultMessage='Wyślij wiadomość'/></button>
        </S.FormWrapper>
      </form>
    </S.ContactWrapper>
  );
};
