import styled from "styled-components";
import { DEVICE } from "../../const/mediaQuery";

export const ToolsWrapper = styled.div`
  flex: 1;
  color: white;
  max-width: 1200px;
  font-size: 16px;
  line-height: 1.5;
`;

export const Partnership = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  max-width: 375px;
  white-space: pre-line;
  span {
    color: orange;
  }
`;

export const Tile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  @media ${DEVICE.tablet} {
    flex-direction: row;
  }
  @media ${DEVICE.laptop} {
    > :first-child {
      margin-right: 24px;
    }
  }
`;

export const ImageWrapper = styled.div<{ count: number }>`
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 375px;
  height: 250px;
  @media ${DEVICE.laptop} {
    width: 600px;
    height: 450px;
  }
  img {
    border-radius: 15px;
    width: 325px;
    position: absolute;
    transition: opacity 0.5s linear;
    @media ${DEVICE.laptop} {
      width: 500px;
    }
  }

  .ferrari {
    opacity: ${(props) => (props.count === 0 ? "1" : "0")};
  }

  .mazda {
    opacity: ${(props) => (props.count === 1 ? "1" : "0")};
  }

  .mini {
    opacity: ${(props) => (props.count === 2 ? "1" : "0")};
  }
`;
