import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Main } from "./components/Main/Main";
import { About } from "./components/About/About";
import { Contact } from "./components/Contact/Contact";
import { Location } from "./components/Location/Location";
import { Tools } from "./components/Tools/Tools";

import { APP_LINKS } from "./components/Main/main.constants";
import { AVAILABLE_LANGUAGE } from "./components/Header/header.constants";

import "./App.css";
import { getCookie } from "./utils/app.utils";
import { APP_MESSAGES } from "./const/app.constants";

function App() {

  const [selectedLanguage, setSelectedLanguage] = useState(AVAILABLE_LANGUAGE.PL);
  
  useEffect(() => {
    const cookieLocale = getCookie('locale');
    if(cookieLocale) {
      setSelectedLanguage(cookieLocale);
    }
  },[])

  return (
    <div className="App">
      <BrowserRouter>
        <IntlProvider messages={APP_MESSAGES[selectedLanguage]} locale={selectedLanguage} defaultLocale={AVAILABLE_LANGUAGE.PL} >
          <Header selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path={`/${APP_LINKS.CONTACT}`} element={<Contact />} />
            <Route path={`/${APP_LINKS.LOCATION}`} element={<Location />} />
            <Route path={`/${APP_LINKS.ABOUT}`} element={<About />} />
            <Route path={`/${APP_LINKS.TOOLS}`} element={<Tools />} />
            <Route path="*" element={<Main />} />
          </Routes>
          <Footer />
        </IntlProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
