export const HELLCARS_POSITION = {
  lat: 50.0789579330714,
  lng: 20.030530936070896,
};

export const MAP_STYLE = {
  height: "500px",
};

export const DEFAULT_ZOOM_VALUE = 14;
