import styled from "styled-components";

export const ContactWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 16px;
  line-height: 1.5;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 12px;
  }

  > :not(label, button) {
    border-radius: 5px;
    border: 2px solid rgba(109, 109, 109, 0.75);
  }

  input {
    width: 300px;
    height: 30px;
  }

  textarea {
    width: 300px;
    resize: none;
    padding-top: 8px;
  }

  button {
    padding: 4px;
    background-color: transparent;
    border: orange solid 1px;
    border-radius: 10px;
    outline: orange solid 1px;
    width: 200px;
    color: white;
    cursor: pointer;
  }

  > * {
    &:focus {
      border-color: orange;
      outline: orange solid 1px;
      box-shadow: 0px 0px 15px white;
    }
  }
`;
