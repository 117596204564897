import { FormattedMessage } from "react-intl";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import * as S from "./Location.styles";
import {
  DEFAULT_ZOOM_VALUE,
  HELLCARS_POSITION,
  MAP_STYLE,
} from "./location.constants";
import hellcarsIcon from "../../assets/hellcars.png";

export const Location = () => {
  return (
    <S.LocationWrapper>
      <S.TextWrapper>
        <h1><FormattedMessage id="LOCATION_HEADER" defaultMessage="Mobilny serwis szyb samochodowych" /></h1>
        <p><FormattedMessage id="LOCATION_FIRST_PARAGRAPH" defaultMessage="Dojeżdżamy do klientów na terenie Krakowa i okolic." /></p>
        <p><FormattedMessage id="LOCATION_SECOND_PARAGRAPH" defaultMessage="Biuro serwisu mieści się na osiedlu Teatralnym w Krakowie." /></p>
      </S.TextWrapper>
      <APIProvider apiKey={"AIzaSyDGaqs6Bnzj3rVYVuwrA7cj-RHdZKJJDKA"}>
        <Map
          defaultCenter={HELLCARS_POSITION}
          defaultZoom={DEFAULT_ZOOM_VALUE}
          style={MAP_STYLE}
          minZoom={12}
          maxZoom={18}
        >
          <Marker position={HELLCARS_POSITION} icon={hellcarsIcon} />
        </Map>
      </APIProvider>
    </S.LocationWrapper>
  );
};
