import styled, { css } from "styled-components";
import { DEVICE } from "../../const/mediaQuery";

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  @media ${DEVICE.tablet} {
    flex-direction: row;
    height: 150px;
  }
`;

export const LogoContainer = styled.img`
  object-fit: contain;
  height: 45px;
`;

export const BreakLine = styled.div`
  width: 325px;
  background-color: rgba(247, 185, 40, 1);
  height: 2px;
  @media ${DEVICE.tablet} {
    height: 140px;
    width: 2px;
  }
`;

export const TextWrapper = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  & > span {
    text-align: center;
    margin-top: 8px;
    width: 160px;
  }
  @media ${DEVICE.tablet} {
    margin-right: 40px;
    flex-direction: row;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  border-radius: 20px;
  padding: 4px;
  background-color: white;
  margin-right: 12px;
  img {
    object-fit: contain;
  }
`;

export const IconWrapper = styled.div<{ clickable?: boolean }>`
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  span:nth-child(3) {
    margin-left: 8px;
    color: rgba(247, 185, 40, 1);
  }
  ${(props) =>
    props.clickable &&
    css`
      span {
        cursor: pointer;
      }
    `}

  @media ${DEVICE.tablet} {
    margin-left: 36px;
    margin-bottom: 4px;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  margin-top: 12px;
`;
