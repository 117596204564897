import styled from "styled-components";

export const AboutWrapper = styled.div`
  padding: 20px;
  flex: 1;
  color: white;
  max-width: 820px;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
`;
