import type { ContainerType } from "./Container/container.types";

import map from "../../assets/map.png";
import tools from "../../assets/tools.png";
import user from "../../assets/user.png";
import form from "../../assets/sign-up.png";

export const APP_LINKS = {
  CONTACT: "kontakt",
  LOCATION: "lokalizacja",
  TOOLS: "uslugi",
  ABOUT: "o-mnie",
};

export const TILES: ContainerType[] = [
  {
    icon: user,
    link: APP_LINKS.ABOUT,
    title: "MAIN_TILE_ABOUT_US",
  },
  {
    icon: tools,
    link: APP_LINKS.TOOLS,
    title: "MAIN_TILE_SERVICES",
  },
  {
    icon: map,
    link: APP_LINKS.LOCATION,
    title: "MAIN_TILE_LOCATION",
  },
  {
    icon: form,
    link: APP_LINKS.CONTACT,
    title: "MAIN_TILE_CONTACT",
  },
];
