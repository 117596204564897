export const SIZE = {
  mobile: "375px",
  tablet: "768px",
  laptop: "1024px",
};

export const DEVICE = {
  mobile: `(max-width: ${SIZE.mobile})`,
  tablet: `(min-width: ${SIZE.tablet})`,
  laptop: `(min-width: ${SIZE.laptop})`,
};
