import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { APP_LINKS } from "../Main/main.constants";
import * as S from "./Tools.styles";
import ferrari from "../../assets/ferrari.webp";
import mazda from "../../assets/mazda.webp";
import mini from "../../assets/mini.webp";
import { FormattedMessage } from "react-intl";

export const Tools = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => (prevCount + 1) % 3);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const handleOnContactClick = useCallback(() => {
    navigate(`/${APP_LINKS.CONTACT}`, { replace: true });
  }, [navigate]);

  return (
    <S.ToolsWrapper>
      <S.Tile>
        <S.ImageWrapper count={count}>
          <img src={ferrari} alt="partnership" className="ferrari" />
          <img src={mazda} alt="partnership" className="mazda" />
          <img src={mini} alt="partnership" className="mini" />
        </S.ImageWrapper>
        <S.Partnership>
          <h2><FormattedMessage id="TOOLS_FIRST_HEADER" defaultMessage='Oferta indywidualna' /></h2>
          <p><FormattedMessage id='TOOLS_FIRST_PARAGRAPH' defaultMessage='Oferujemy dojazd do klienta na terenie Krakowa i okolicznych gmin. Szybko realizujemy zlecenia. \n W swoim asortymencie posiadamy szyby wielu producentów. W sprawie wyceny zapraszamy do ' />
            <span onClick={handleOnContactClick}><FormattedMessage id="TOOLS_CONTACT" defaultMessage='kontaktu' /></span>
            <FormattedMessage id="TOOLS_SECOND_PARAGRAPH" defaultMessage=' Działamy kompleksowo, dlatego też w naszej ofercie posiadamy również:' />
            <ul>
              <li><FormattedMessage id="TOOLS_LIST_FIRST_ELEMENT" defaultMessage='wycieraczki samochodowe' /></li>
              <li><FormattedMessage id="TOOLS_LIST_SECOND_ELEMENT" defaultMessage='aplikację niewidzialnej wycieraczki' /></li>
              <li><FormattedMessage id="TOOLS_LIST_THIRD_ELEMENT" defaultMessage='aplikację antypary' /></li>
              <li><FormattedMessage id="TOOLS_LIST_FOURTH_ELEMENT" defaultMessage='polerowanie reflektorów' /></li>
            </ul>
          </p>
        </S.Partnership>
      </S.Tile>
      <S.Tile>
        <S.Partnership>
          <h2><FormattedMessage id="TOOLS_THIRD_HEADER" defaultMessage='Współpraca z naszą firmą' /></h2>
          <p>
            <FormattedMessage id="TOOLS_THIRD_PARAGRAPH" defaultMessage='Zapraszamy do współpracy warszataty samochodowe, blacharskie, lakiernicze, komisy itp. Jesteśmy otwarci na wszelkie propozycje stałej współpracy i oferujemy preferencyjne warunki.' />
          </p>
        </S.Partnership>
        <S.ImageWrapper count={count}>
          <img src={ferrari} alt="partnership" className="ferrari" />
          <img src={mazda} alt="partnership" className="mazda" />
          <img src={mini} alt="partnership" className="mini" />
        </S.ImageWrapper>
      </S.Tile>
    </S.ToolsWrapper>
  );
};
