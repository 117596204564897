import { FormattedMessage } from "react-intl";
import * as S from "./About.styles";

export const About = () => {
  return (
    <S.AboutWrapper>
      <h2><FormattedMessage id="ABOUT_FIRST_HEADER" defaultMessage='Nasza firma'/></h2>
      <p>
        <FormattedMessage id="ABOUT_FIRST_PARAGRAPH" defaultMessage='Jesteśmy firmą zajmującą się wymianą szyb samochodowych i posiadamy w tej dziedzinie wieloletnie doświadczenie. Do każdego zlecenia podchodzimy z pasją i zawsze dajemy z siebie 100%. Na pierwszym miejscu stawiamy zadowolenie klienta oraz jego wygodę, dlatego też naszą działalność prowadzimy mobilnie. Dzięki takiemu rozwiązaniu zaangażowanie klienta ogranicza się do wykonania telefonu i rozmowy z nami, resztą zajmujemy się my. Zjawiamy się pod wskazanym adresem i w umówionym terminie, bez względu na to, czy jest to osiedle mieszkalne, domek jednorodzinny czy parking przed biurowcem. Nie ograniczamy się do montażu szyb jednego producenta, doskonale rozumiemy, że każdy klient może mieć własne preferencje, dlatego też współpracujemy z dostawcami szyb zarówno markowych, jak i tańszych zamienników. Obecnie świadczymy usługi na terenie Krakowa oraz okolic, mamy jednak ambicje na zwiększanie rejonu naszego działania. Mamy nadzieję w przyszłości stać się jednym z czołowych serwisów w kraju. Pragniemy także w przyszłości rozszerzać zakres naszych usług.' />
      </p>
      <FormattedMessage id="ABOUT_SECOND_PARAGRAPH" defaultMessage='Dziękujemy wszystkim naszym klientom za zaufanie. Jesteśmy dumni, że jesteście Państwo zadowoleni z naszych usług i bardzo wysoko oceniacie naszą pracę i zaangażowanie.' />
    </S.AboutWrapper>
  );
};
