import { FormattedMessage } from "react-intl";
import * as S from "./Footer.styles";
import stopka from "../../assets/stopka_kontaktowa.webp";
import smartphone from "../../assets/smartphone.png";
import facebook from "../../assets/facebook.png";
import location from "../../assets/location.png";

export const Footer = () => {
  return (
    <S.FooterWrapper>
      <S.TextWrapper>
        <S.LogoContainer src={stopka} />
        <span>
          <FormattedMessage id="FOOTER_FIRST_PARAGRAPH" defaultMessage='Sprzedaż i wymiana szyb samochodowych z dojazdem do klienta' />
        </span>
      </S.TextWrapper>
      <S.BreakLine />
      <S.Icons>
        <S.IconWrapper>
          <S.IconContainer>
            <img src={smartphone} alt="Smartphone" height={24} width={24} />
          </S.IconContainer>
          <span>662 140 603</span>
          <span>Krzysztof</span>
        </S.IconWrapper>
        <S.IconWrapper clickable>
          <S.IconContainer>
            <img src={facebook} alt="Facebook" height={24} width={24} />
          </S.IconContainer>
          <span
            onClick={() => window.open("https://www.facebook.com/hellcars")}
          >
            @hellcars
          </span>
        </S.IconWrapper>
        <S.IconWrapper clickable>
          <S.IconContainer>
            <img src={location} alt="Google maps" height={24} width={24} />
          </S.IconContainer>
          <span
            onClick={() => window.open("https://goo.gl/maps/R4Nq5nwMSnxj43fP7")}
          >
            Osiedle Teatralne 2
          </span>
        </S.IconWrapper>
      </S.Icons>
    </S.FooterWrapper>
  );
};
