import styled from "styled-components";
import { DEVICE, SIZE } from "../../const/mediaQuery";

export const HeaderWrapper = styled.div<{ small: boolean }>`
  height: ${(props) => (props.small ? "auto" : "280px")};
  display: flex;
  font-weight: 800;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-width: ${SIZE.mobile};
    max-height: 200px;
    @media ${DEVICE.tablet} {
      max-width: ${SIZE.tablet};
      max-height: 250px;
    }
    @media ${DEVICE.laptop} {
      max-width: ${SIZE.laptop};
      max-height: 250px;
    }
    object-fit: contain;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const NavbarButton = styled.div`
  cursor: pointer;
  margin: 8px 12px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > :first-child {
    height: 80px;
  }
`;

