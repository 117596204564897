import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import * as S from "./Header.styles";
import logo from "../../assets/hellcarslogo.webp";
import text from "../../assets/hellcarsNapis.png";
import smallLogo from "../../assets/stopka_kontaktowa.webp";
import { NAVBAR_LIST } from "./header.constants";
import { FlagDropdown } from "./components/FlagDropdown";
import type { HeaderProps } from "./header.types";

export const Header = ({selectedLanguage, setSelectedLanguage}: HeaderProps) => {
  const location = useLocation();

  const showMenu = location.pathname !== "/";

  return (
    <S.HeaderWrapper small={showMenu}>
      <FlagDropdown selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
      {!showMenu ? (
        <img src={logo} alt="Logo" height={250} width={450} />
      ) : (
        <>
          <S.ImageWrapper>
            <img src={smallLogo} alt="smallLogo" height={80} width={360} />
            <img src={text} alt="text" height={80} width={360} />
          </S.ImageWrapper>
          <S.MenuWrapper>
            {NAVBAR_LIST.map((item, index) => (
              <S.NavbarButton key={`${index}`}>
                <Link
                  to={item.link}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <FormattedMessage id={item.text} defaultMessage={item.text} />
                </Link>
              </S.NavbarButton>
            ))}
          </S.MenuWrapper>
        </>
      )}
    </S.HeaderWrapper>
  );
};
