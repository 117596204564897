import styled from "styled-components";

export const FlagWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const FlagComponent = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: -15px;
  background: #141414;
  border: 1px solid #000;
  border-radius: 4px;
  z-index: 1;
  width: max-content;
`;

export const DropdownItem = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;


export const DropdownButton = styled.div`
  background: none;
  border: none;
  cursor: pointer;
`;