import { useState, useCallback } from "react";
import { AVAILABLE_LANGUAGES_LIST } from "../header.constants"
import { FlagComponent, DropdownButton, DropdownMenu, DropdownItem, FlagWrapper } from "./FlagDropdown.styles";
import type { HeaderProps } from "../header.types";

export const FlagDropdown = ({ selectedLanguage, setSelectedLanguage}: HeaderProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const onSelectLanguage = useCallback((languageCode: string) => {
        setSelectedLanguage(languageCode);
        document.cookie = `locale=${languageCode};`;
    },[setSelectedLanguage]);

    const handleSelect = useCallback((languageCode: string) => {
        onSelectLanguage(languageCode);
        setIsOpen(false);
    },[onSelectLanguage]);

    

    return (
        <FlagWrapper>
            <FlagComponent>
                <DropdownButton onClick={toggleDropdown} >
                    <img
                        src={AVAILABLE_LANGUAGES_LIST[selectedLanguage].icon}
                        alt={selectedLanguage}
                        width={32}
                        height={24}
                    />
                </DropdownButton>
                {isOpen && (
                    <DropdownMenu>
                        {Object.values(AVAILABLE_LANGUAGES_LIST).map(({ icon, text }) => (
                            <DropdownItem
                                key={text}
                                onClick={() => handleSelect(text)}
                            >
                                <img src={icon} alt={text} width={32} height={24}/>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                )}
            </FlagComponent>
        </FlagWrapper>
    )
}